import React from "react"
import Layout from "../components/Layout"
import Nav from "../components/Nav"
import { Link } from "gatsby"
import richBrown from "../images/rich_brown.jpeg"
import Quote from "../components/Quote"

import shopifyLogo from "../images/shopify-logo.png"
import hubspotLogo from "../images/hubspot-logo.png"
import atlassianLogo from "../images/atlassian-logo.png"
import amazonLogo from "../images/amazon-logo.png"


export default function Home() {
  return (
    <Layout>
      <Nav></Nav>
      <section className="section--container">
        <section className="section--homepage">
          <h1>Kevan Lin is a product design leader with a strong focus in <strong>user research</strong> and <strong>storytelling</strong>, working remotely from Raleigh, North Carolina.</h1>
          <section className="section--companyGrid">
            <Link to="/case-studies/shopify/multi-currency-payment-methods" className="button" role="button">
              <div className="company--container" role="group">
                <div className="company--logo">
                  <img src={shopifyLogo} alt="Shopify logo" />
                </div>
                <div className="company--group">
                  <div className="company--text">
                    <p><strong>Shopify</strong></p>
                    <p>Supporting savvy international entrepreneurs.</p>
                  </div>
                  <button type="button" class="button-cta">View</button>
                </div>
              </div>
            </Link>
            <Link to="/case-studies/hubspot/hubspot-network" className="button" role="button">
              <div className="company--container" role="group">
                <div className="company--logo">
                  <img src={hubspotLogo} alt="Shopify logo" />
                </div>
                <div className="company--group">
                  <div className="company--text">
                    <p><strong>Hubspot</strong></p>
                    <p>LinkedIn network for a HubSpot world.</p>
                  </div>
                  <button type="button" class="button-cta">View</button>
                </div>
              </div>
            </Link>
            <Link to="case-studies/atlassian/personalized-question-asking-experience" className="button" role="button">
              <div className="company--container" role="group">
                <div className="company--logo">
                  <img src={atlassianLogo} alt="Shopify logo" />
                </div>
                <div className="company--group">
                  <div className="company--text">
                    <p><strong>Atlassian</strong></p>
                    <p>Connecting Atlassian customers to answers.</p>
                  </div>
                  <button type="button" class="button-cta">View</button>
                </div>
              </div>
            </Link>
            <Link to="case-studies/amazon/comparison-shopping" className="button" role="button">
              <div className="company--container" role="group">
                <div className="company--logo">
                  <img src={amazonLogo} alt="Shopify logo" />
                </div>
                <div className="company--group">
                  <div className="company--text">
                    <p><strong>Amazon</strong></p>
                    <p>Online comparison shopping made easy.</p>
                  </div>
                  <button type="button" class="button-cta">View</button>
                </div>
              </div>
            </Link>
          </section>
        </section>
      </section>
    </Layout>
  )
}